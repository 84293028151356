

/*
    网络请求
*/
import { Toast } from 'vant';
import axios from "axios";
import store from '@/store/index';
const request = axios.create({
    timeout: 60000,
});
request.interceptors.request.use(
    (config) => {
        store.commit('showLoading')
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
request.interceptors.response.use(
    (response) => {
        store.commit('hideLoading')
        return response;
    },
    (err) => {
        const { response } = err;
        if (response) {
            switch (response.status) {
                case 400:
                    console.log(response.data);
                    break;
                case 401:
                    console.log(response.data);
                    break;
                case 403:
                    console.log(response.data);
                    break;
                case 404:
                    console.log(response.data);
                    break;
                default:
                    console.log(response.data);
                    break;
            }
        } else {
            Toast('网络出小差了~，稍后再试');
            console.log("请求中断了！！！");
        }
        store.commit('hideLoading')
    }
);
let Request = (params) => {
    return new Promise((resolve, reject) => {
        request({
                ...params,
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export default Request;